import React, { Component } from 'react';
import Tagbar from '../components/Tagbar';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { render } from 'react-dom';
import LoadingBar from 'react-top-loading-bar'
import { ShimmerText } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            womenName: "",
            womenAvatar: "",
            shortBio: "",
            womenBio: "",
            womenTag: [],
             latestPosts: [],
            isLoaded: false,
            pageTitle: ''
        };
        //onClick={() => this.props.startLoading()}

    }

    componentDidMount() {

        const { id } = this.props.match.params;

        axios({
            method: "post",
            url: 'https://mistword.com/mistbox/womendetails',
            data: {
                profileId: id
            },
        }).then(response => {
            //const details = JSON.stringify(response.data.woDetails.woName);
            //const details = response.data.womenTag;

            this.setState({
                isLoaded: true,
                womenName: response.data.womenName,
                womenAvatar: response.data.womenAvatar,
                shortBio: response.data.shortInfo,
                womenBio: response.data.womenBio,
                womenTag: response.data.womenTag,
                pageTitle: 'Welcome Here',
                latestPosts: response.data.latestPosts,
            });
        });
    }

    render() {
        const { womenName } = this.state;
        const { womenAvatar } = this.state;
        const { shortBio } = this.state;
        const { womenBio } = this.state;
        const { womenTag } = this.state;
const { latestPosts } = this.state;
        const isLoaded = this.state.isLoaded;
        const pageTitle = "Details of profile";

        if (!isLoaded) {
            return (<>
                <div className="articleDetailsContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8  col-md-12 col-sm-12">
                                <div className="articleDetaileInner"></div>
                                <ShimmerThumbnail height={450} />
                                <ShimmerText line={30} gap={10} />
                            </div>
                            <div className="col-lg-4  col-md-8 col-sm-12 pl-4">
                                <h5>Latest Article</h5>
                                 <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />;
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        } else {
            return (
                <>
                    <Helmet>
                        ‍<title>{pageTitle}</title>
                    </Helmet>
                    <div className="articleDetailsContainer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8  col-md-12 col-sm-12">
                                    <div className="articleDetaileInner">
                                        <img src={`/profilephoto/${womenAvatar}`} className="img-move" alt={womenName} title={womenName} />

                                        <h3>{womenName}</h3>
                                        <span className="publish"><strong>Vivek</strong> | January 14 2021</span>

                                        <div className="articleShortInfo">
                                            {shortBio}
                                        </div>

                                        <div className="lineBox mt-3"></div>
                                        <div dangerouslySetInnerHTML={{ __html: womenBio }} />
                                        <div className="lineBox"></div>

                                        <div className="tagBox">
                                            {womenTag.map(tagInfo => (
                                                <a href={tagInfo.catId}>{tagInfo.catName} </a>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                               <div class="col-lg-4  col-md-2 col-sm-4 pl-4">
                                    <h5>Latest Article</h5>
                                    <div class="row mt-4">
                                        {latestPosts.map(lPost => (
                                            <div class="col-lg-12 latestArticle">
                                                <div class="iconBox">

                                                    <img src="/profilephoto/no-image.png" class="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" />

                                                </div>
                                                <div class="serviceText">
                                                    <h5>
                                                        <Link to={`/details/${lPost.userLink}`}
                                                        > {lPost.woName}  </Link>
                                                    </h5>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Details;