import React, { Component } from 'react';
import axios from 'axios';

class Login extends Component {

    
    componentDidMount() {
        this.props.stopLoading();
    }
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {}
        };
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    doLogin() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields['email']) {
            formIsValid = false;
            errors['email'] = ' Enter email id';
            //alert('Hi! Admin');
        }

        if (typeof fields['email'] !== 'undefined') {
            let lastAtPos = fields['email'].lastIndexOf('@');
            let lastDotPos = fields['email'].lastIndexOf('.');

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields['email'].indexOf('@@') == -1 &&
                    lastDotPos > 2 &&
                    fields['email'].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors['email'] = 'Email is not valid';
            }
        }

        if (!fields['password']) {
            formIsValid = false;
            errors['password'] = 'Enter Password';
        }

        if (typeof fields['password'] !== 'undefined') {
            if (!fields['password'].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors['password'] = 'Only letters & numbers';
            }
        }

        this.setState({ errors: errors });

        if (formIsValid) {
            /*---------------------------*/
            let formData = new FormData();
            let lName = fields['email'];
            let lPass = fields['password'];
            formData.append('loginName', lName);
            formData.append('loginPassword', lPass);
            /*---------------------------*/

            axios({
                method: 'post',
                url: '/pages/loginApi.php',
                data: formData,
                config: {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            })
                .then(function (response) {
                    //handle success
                    console.log(response);
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        }

        return formIsValid;
    }

    render() {
        return (
            <div class="loginContainer">
                <div class="form-createaccount">
                    <h4 class="mb-3">Sign In</h4>
                    <div class="form-group">


                        <input type="text" name="emailId" value="" class="form-control ipBox" id="emailId" maxlength="100"
                            placeholder="Email Id" autocomplete="off"
                             refs="email"
                            onChange={this.handleChange.bind(this, 'email')}
                            value={this.state.fields['email']}
                        />
                        <span className="error errorClass">
                            {this.state.errors['email']}
                        </span>
                    </div>
                    <div class="form-group">  
                               <input
                                   class="form-control ipBox"
                                    refs="password"
                                    type="Password"
                                    size="100"
                                    placeholder="Password"
                                    maxlength="100"
                                    autocomplete="off"
                                    onChange={this.handleChange.bind(this, 'password')}
                                    value={this.state.fields['password']}
                                />
                                <span className="error errorClass">
                                    {this.state.errors['password']}
                                </span>
                    </div>
                    <div class="form-group">
                         
                        <button
                                class="btn loginBtn"
                                onClick={() => {
                                    this.doLogin();
                                }}
                            >
                                Click me!
                            </button>
                    </div>
                    <a href="" class="forgotPass">Forgot Password?</a>
                    <span class="createAcc">Dont have an account?</span> <a href="" class="createAcc">Register</a>
                </div>
            </div>
        );
         this.props.stopLoading();
    }
    
}

export default Login;


/*<div class="global-container">
                <div class="card login-form">
                    <div class="card-body">
                        <h3 class="card-title text-center">Log</h3>
                        <div class="card-text">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input
                                    class="form-control form-control-sm"
                                    refs="email"
                                    type="text"
                                    size="30"
                                    placeholder="Email"
                                    onChange={this.handleChange.bind(this, 'email')}
                                    value={this.state.fields['email']}
                                />
                                <span className="error errorClass">
                                    {this.state.errors['email']}
                                </span>
                            </div>
                            <br />
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>

                                <input
                                    class="form-control form-control-sm"
                                    refs="password"
                                    type="Password"
                                    size="100"
                                    placeholder="Password"
                                    onChange={this.handleChange.bind(this, 'password')}
                                    value={this.state.fields['password']}
                                />
                                <span className="error errorClass">
                                    {this.state.errors['password']}
                                </span>
                            </div>

                            <button
                                class="btn btn-primary btn-block"
                                onClick={() => {
                                    this.doLogin();
                                }}
                            >
                                Click me!
                            </button>

                            <div class="sign-up">
                                Don't have an account? <a href="#">Create One</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */