import React, { Component } from 'react';

class About extends Component {

    componentDidMount() {
        this.props.stopLoading();
    } 

    render() {
        return <div class="aboutUsContainer">
            <div class="container">
                <div class="row mb-5">
                    <div class="col-lg-6  col-md-2 col-sm-4">
                        <img src="images/1.png" class="AnimImg" />
                    </div>
                    <div class="col-lg-6  col-md-2 col-sm-4 aboutInner">
                        <h1 class="mt-5">It's all about begining</h1>
                        <p>In the real world, you don't know how long the content will be. Writers will write what they
                            need. Photos will be cropped to the shape that they should be. That's good. Make a
                            system that allows this. You want it to be flexible and robust. <br /><br />
                            In the real world, you don't know how long the content will be. Writers will write what they
                            need. Photos will be cropped to the shape that they should be. That's good. Make a
                            system that allows this. You want it to be flexible and robust.</p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-lg-6  col-md-2 col-sm-4 aboutInner">

                        <h1 class="mt-5">Let the world Know</h1>
                        <p>In the real world, you don't know how long the content will be. Writers will write what they
                            need. Photos will be cropped to the shape that they should be. That's good. Make a
                            system that allows this. You want it to be flexible and robust. <br /><br />
                            In the real world, you don't know how long the content will be. Writers will write what they
                            need. Photos will be cropped to the shape that they should be. That's good. Make a
                            system that allows this. You want it to be flexible and robust.</p>
                    </div>
                    <div class="col-lg-6  col-md-2 col-sm-4">
                        <img src="images/about-img.svg" class="AnimImg" />
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default About;