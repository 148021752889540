//import logo from './logo.svg';
import './App.css';
import './css/webstyle.css'
import React, { Component, useRef, useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";


import Navbars from './components/Navbars';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import Details from './pages/Details';
import Contact from './pages/Contact';
import Allprofile from './pages/Allprofile';
import LoadingBar from 'react-top-loading-bar'
import Typewriter from 'typewriter-effect/dist/core';
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const App = () => {
  const ref = useRef(null);

  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <BrowserRouter>
        <Navbars
          startLoading={() => {
            ref.current.continuousStart();
          }}
        />
        <Switch>
          <Route exact path="/" component={Home}>
            <Home
              stopLoading={() => {
                ref.current.complete();
              }}
            />
          </Route>

                   <Route path="/about">
            <About
              stopLoading={() => {
                ref.current.complete();
              }}
            />
          </Route>
          <Route path="/login" component={Login}>
            <Login
              stopLoading={() => {
                ref.current.complete();
              }}
            />
          </Route>
          <Route path="/contact" component={Contact}>
            <Contact
              stopLoading={() => {
                ref.current.complete();
              }}
            />
          </Route>
          <Route path="/details/:id" component={Details} stopLoading={() => {
            ref.current.complete();
          }} />

          <Route path="/allprofile">
            <Allprofile
              stopLoading={() => {
                ref.current.complete();
              }}
            />
          </Route>
\
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;