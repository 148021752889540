import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (<div class="sectionFooter">
            <div class="container">
                <div class="site-footer">
                    <div class="container">
                        <div class="row ml-2 mr-2">
                            <div class="col-lg-4  col-md-4 col-sm-4 ">
                                <h5>Important Links</h5>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div class="col-lg-4  col-md-4 col-sm-4 ">
                                <h5>Write Us</h5>
                                <ul>
                                    <li> <a href="/wmonk"> Email : contact@mistword.com</a> </li>
                                </ul>
                            </div>
                            <div class="col-lg-4  col-md-4 col-sm-4 ">
                                <h5>Connect Us</h5>
                                <ul class="social">
                                    <li><a href="https://www.facebook.com/profile.php?id=100076332976194" target='_blank'><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="/wmonk"><i class="fa fa-twitter"></i> </a></li> 
                                    <li><a href="/wmonk"><i class="fa fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;
