import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Tagbar extends Component {
    render() {
        return (
            <OwlCarousel
                nav={true}
                loop
                margin={10} 
                autoWidth={true}
                navText={["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]}

            >
                
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Education</a></div>
                <div class="item"><a href="/det">Sport</a></div>
                <div class="item"><a href="/det">Chemistry</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Education</a></div>
                <div class="item"><a href="/det">Sport</a></div>
                <div class="item"><a href="/det">Chemistry</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Education</a></div>
                <div class="item"><a href="/det">Sport</a></div>
                <div class="item"><a href="/det">Chemistry</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Education</a></div>
                <div class="item"><a href="/det">Sport</a></div>
                <div class="item"><a href="/det">Chemistry</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Science</a></div>
                <div class="item"><a href="/det">Education</a></div>
                <div class="item"><a href="/det">Sport</a></div>
                <div class="item"><a href="/det">Chemistry</a></div>
                <div class="item"><a href="/det">Science</a></div>
                    
            </OwlCarousel>
        );
    }
}

export default Tagbar;
