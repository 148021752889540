import React, { Component } from 'react';
import Tagbar from '../components/Tagbar';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import Typewriter from 'typewriter-effect';//https://npm.io/package/typewriter-effect
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerText } from "react-shimmer-effects";
import { ShimmerPostList } from "react-shimmer-effects";
import { ShimmerCategoryList } from "react-shimmer-effects";
//https://reactjsexample.com/customizable-shimmer-effects-for-react/

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            tags: [],
            latestPosts: [],
            isLoaded: false,
            pageTitle: '',
            currentQuote: ''
        };

    }

    componentDidMount() {
        axios({
            url: 'https://mistword.com/mistbox/indexpage'
        }).then(response => {
            this.setState({
                isLoaded: true,
                items: response.data.topTen,
                tags: response.data.woTags,
                latestPosts: response.data.latestPosts,
                pageTitle: 'Welcome to Mistword',
                currentQuote: response.data.woQuotes
            });
            this.props.stopLoading();
        });

    }

    render() {
        const { items } = this.state;
        const { tags } = this.state;
        const { latestPosts } = this.state;
        const isLoaded = this.state.isLoaded;
        const pageTitle = this.state.pageTitle;
        const currentQuote = this.state.currentQuote;




        if (!isLoaded) {
            return (<><div className="heroContainer mb-4">
                <ShimmerText line={3} gap={10} />
            </div>
                <div className="homePageContainer">
                    <div className="container">
                        <div className="row">
                            <div className="tagBox pl-4 pr-4 mb-4">
                                <Tagbar />
                            </div>
                            <div className="col-lg-8  col-md-2 col-sm-4">
                                <ShimmerPostList postStyle="STYLE_FOUR" col={2} row={4} gap={30} />;
                            </div>
                            <div class="col-lg-4  col-md-2 col-sm-4 pl-4">
                                <h5>Latest Article</h5>
                               
                                    <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />;
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>)


        } else {
            return (
                <>
                    <Helmet>
                        ‍<title>{pageTitle}</title>
                        <meta name="description" content="App Description" />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>


                    <div className="heroContainer mb-4">
                        <div dangerouslySetInnerHTML={{ __html: this.state.currentQuote }} />
                    </div>
                    <div className="homePageContainer">
                        <div className="container">
                            <div className="row">
                                <div className="tagBox pl-4 pr-4 mb-4">
                                    <Tagbar />
                                </div>
                                <div className="col-lg-8  col-md-2 col-sm-4">
                                    <main>
                                        {items.map(item => (
                                            <article>
                                                <img src={`/profilephoto/${item.woAvatar}`} className="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" />

                                                <div className="articleInside">
                                                    <h3>
                                                        <Link to={`/details/${item.userLink}`} className="artLink"
                                                        > {item.woName}  </Link>

                                                    </h3>
                                                    <p>{item.siInfo} </p>
                                                </div>

                                            </article>
                                        ))}
                                    </main>
                                </div>
                                <div class="col-lg-4  col-md-2 col-sm-4 pl-4">
                                    <h5>Latest Article</h5>
                                    <div class="row mt-4">
                                        {latestPosts.map(lPost => (
                                            <div class="col-lg-12 latestArticle">
                                                <div class="iconBox">

                                                    <img src="/profilephoto/no-image.png" class="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" />

                                                </div>
                                                <div class="serviceText">
                                                    <h5>
                                                        <Link to={`/details/${lPost.userLink}`}
                                                        > {lPost.woName}  </Link>
                                                    </h5>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div class="tagBox pl-2">
                                        {tags.map(tagInfo => (
                                            <a href="">{tagInfo.catName} </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 viewMoreContainer">
                        <Link to="allprofile">View All</Link>
                    </div>
                </>
            );
        }
    }
}

export default Home;
/*<a href={`/details/${item.userLink}`} className="artLink">
                                                        {item.woName} </a> */
//<img src={require('../profilephoto/anna-chandy.jpg')} className="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" />

/*import React, { Component } from 'react';
import Tagbar from '../components/Tagbar';
import axios from 'axios';
import { Helmet } from "react-helmet";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            tags:[],
            isLoaded: false,
            pageTitle: ''
        };
    }

    componentDidMount() {
        axios({
            url: 'http://127.0.0.1/wmonk/api/index'
        }).then(response => {
            this.setState({
                isLoaded: true,
                items: response.data.topTen,
                tags: response.data.woTags,
                pageTitle: 'Welcome Here'
            });
        });
    }

    render() {
        const { items } = this.state;
        const { tags } = this.state;
        const isLoaded = this.state.isLoaded;
        const pageTitle = this.state.pageTitle;

        if (!isLoaded) {
            return <div>Loading ... </div>;
        } else {
            return (
                <>
                    <Helmet>
                        ‍<title>{pageTitle}</title>
                    </Helmet>
                    <div className="heroContainer mb-4">
                    </div>
                    <div className="homePageContainer">
                        <div className="container">
                            <div className="row">
                                <div className="tagBox pl-4 pr-4 mb-4">
                                    <Tagbar />
                                </div>
                                <div className="col-lg-8  col-md-2 col-sm-4">
                                    <main>
                                        {items.map(item => (
                                            <article>
                                                <img src="https://picsum.photos/200" className="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" />

                                                <div className="articleInside">
                                                    <h3><a href="details/anna-chandy" className="artLink">
                                                        {item.woName} </a></h3>
                                                    <p>{item.siInfo} </p>
                                                </div>

                                            </article>
                                        ))}
                                    </main>
                                </div>
                                <div class="col-lg-4  col-md-2 col-sm-4 pl-4">
                                    <h5>Latest Article</h5>
                                    <div class="row mt-4">
                                        <div class="col-lg-12 latestArticle">
                                            <div class="iconBox">
                                                <img src="profileSmall/anna-chandy.png" />
                                            </div>
                                            <div class="serviceText">
                                                <h5>Chartered plane</h5>
                                                <p>2 Far far away, behind the word mountains</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tagBox pl-2">
                                        {tags.map(tagInfo => (
                                            <a href="">{tagInfo.catName} </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </>
                    );
        }
    }
}

export default Home;

                    //<img src={require('../profilephoto/anna-chandy.jpg')} className="img-move" alt="Justice Anna Chandy" title="Justice Anna Chandy" /> */

                    //https://www.codingame.com/playgrounds/6517/react-router-tutorial