import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink as ReactLink } from 'react-router-dom';


class Navbars extends Component {
    render() {
        return (
            <div className="navigationContainer">
                <div className="container">
                    <Navbar collapseOnSelect expand="lg"  >
                        <Navbar.Brand href="/home">MISTWORD</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                            </Nav>
                            <Nav>
                                <NavLink tag={ReactLink} to="/" className="Navs nav-link">Home</ NavLink>

                                <NavLink tag={ReactLink} to="/about" className="Navs nav-link">About Us</ NavLink>

                                <NavLink tag={ReactLink} to="/contact" className="Navs nav-link">Contact Us</ NavLink>



                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                </div>
            </div>
        );
    }
}

export default Navbars;
