import React, { Component } from 'react';

class Contact extends Component {
      componentDidMount() {
        this.props.stopLoading();
    } 
    render() {
        return <div class="contactUsWrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12  col-md-12 col-sm-12">
                        <h1>Contact Us</h1>
                    </div>

                    <div class="col-lg-6  col-md-6 col-sm-6">
                        <div class="leftContactUs">
                            <div class="col-lg-12">
                                <div class="iconBox iconBoxRed">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                                <div class="leftSpan">
                                    <h5>Write Us</h5>
                                    <p>contactus@womonk.com</p>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <div class="iconBox iconBoxRed">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </div>
                                <div class="leftSpan">
                                    <h5>Send feedback</h5>
                                    <p>feedback@womonk.com</p>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <div class="iconBox iconBoxRed">
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </div>
                                <div class="leftSpan">
                                    <h5>Newsletter</h5>
                                    <p>newsletter@womonk.com</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-6  col-md-6 col-sm-6 ">
                        <div class="rightContactUs siteShadow">
                            <h4 class="mb-3">Write Us</h4>
                            <div class="form-group">
                                <input type="text" name="name" value="" class="form-control ipBox" id="emailId" maxlength="100" placeholder="Name" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <input type="text" name="emailId" value="" class="form-control ipBox" id="emailId" maxlength="100" placeholder="Email Id" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control ipBox" id="emailId" maxlength="100"
                                    placeholder="Write Something here...." autocomplete="off"></textarea>
                            </div>
                            <div class="form-group">
                                <input type="button" class="btn loginBtn" value="S E N D" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
        
    }
}

export default Contact;