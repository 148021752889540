import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Tagbar from '../components/Tagbar';
import axios from 'axios';
function Allprofile() {
    const perPage = 3;
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);



    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const getUserList = () => {
            setLoading(true);

            fetch(`https://mistword.com/mistbox/allprofile/${page}`)
                .then((res) => res.json())
                .then((res) => {
                    setTotalPages(res.total_pages);

                    setUserList([...userList, ...res.data]);
                    setLoading(false);
                });
        };
        getUserList();

    }, [page]);



    return (
        <div className="homePageContainer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8  col-md-2 col-sm-4 mt-5 mb-5" >
                        <main>
                            {userList.map((x, i) => {
                                return (<article>
                                    <img src={`/profilephoto/${x.woAvatar}`} className="img-move" />
                                    <div className="articleInside">
                                        <h3>
                                            <Link
                                                to={`/details/${x.userLink}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="artLink"
                                            >{x.woName}</Link>
                                        </h3>
                                        <p>{x.siInfo}</p>
                                    </div>
                                </article>
                                );
                            })}
                        </main>
                        <div className="clearfix"></div>
                        <div class="col-lg-9 viewMoreContainer">


                            {totalPages !== page && (
                                <Link className="btn-load-more" onClick={() => setPage(page + 1)} >{loading ? 'Loading...' : 'Load More'}</Link>

                            )}
                        </div>
                    </div>

                    <div className="col-lg-4  col-md-4 col-sm-4 mt-5 mb-5" >
                        <div class="tagBox pl-2"><a href="">Science </a><a href="">Education </a><a href="">Biology </a><a href="">Botony </a><a href="">Zoology </a><a href="">Sports </a><a href="">Defence </a><a href="">Military </a><a href="">Army </a><a href="">Navy </a><a href="">Airforce </a><a href="">Software </a><a href="">Software Engineer </a><a href="">Physics </a><a href="">Entertainment </a><a href="">Politics </a><a href="">Pilot </a><a href="">Justice </a><a href="">Teacher </a><a href="">Design </a><a href="">Electrical </a><a href="">Mechanical </a><a href="">Astronaut </a><a href="">Boxing </a><a href="">Cricket </a><a href="">Football </a><a href="">Hockey </a><a href="">Business </a><a href="">Bollywood </a><a href="">Cinema </a><a href="">Doctor </a><a href="">Dancer </a><a href="">Hiker </a><a href="">Miss India </a><a href="">Miss Universe </a><a href="">Miss Asia </a><a href="">President </a><a href="">Prime Minster </a><a href="">Chief Minster </a><a href="">Actress </a><a href="">IPS Officer </a><a href="">Poet </a><a href="">Writer </a><a href="">Dare Devil </a><a href="">Photographer </a><a href="">Singer </a><a href="">Musician </a></div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Allprofile;
